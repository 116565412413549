import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, isSupported } from "firebase/analytics";
import debounce from "lodash/debounce";

// Config
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FBA_API_KEY,
  authDomain: process.env.REACT_APP_FBA_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FBA_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FBA_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FBA_MSG_SENDER_ID,
  appId: process.env.REACT_APP_FBA_APP_ID,
  measurementId: process.env.REACT_APP_FBA_MEASUREMENT_ID,
};

// Initialize Firebase
let app;
let analytics;
if (isSupported()) {
  app = initializeApp(firebaseConfig);
  analytics = getAnalytics(app);
} else {
  analytics = null;
  console.warn("Analytics is not supported...");
}

// constant for tag id that matches the spreadsheet
export const PellaAnalyticsTag = {
  HOME: "home_page",
  AR_CONFIGURATION: "ar_configuration_",
  HOME_START_CTA_CLICK: "home_page_start_cta_click",
  HOME_PELLA_EXIT_CTA_CLICK: "home_page_exit_pella.com_link_click",
  FILTER_TAB_SELECT: "ar_selected_tabs_click_",
  AR_CONFIGURATION_CHANGE: "ar_configuration_change_event",
  AR_LAUNCH_EVENT: "ar_experience_launch_event",
  // error
  API_REQUEST_ERROR: "ar_api_request_error",
  API_RETRY_CLICK: "ar_api_retry_cta_click",
  AR_ASSET_LOAD_ERROR: "ar_3d_model_load_error",
  PAGE_NOT_FOUND_ERROR: "page_not_found_error",
};

// methods for page & event log
export const erroStateEventLog = (pageId, customParameters = {}) => {
  if (pageId.length > 0) {
    logEvent(analytics, pageId, customParameters);
  }
};

export const pageViewEventLog = (pageId, arAvailable, browserName) => {
  if (pageId.length > 0) {
    logEvent(analytics, "screen_view", {
      firebase_screen: pageId,
      ar_available: arAvailable,
      browserName: browserName,
    });
  }
};

export const clickEventLog = (eventName, custumParameters = {}) => {
  if (eventName.length > 0) {
    logEvent(analytics, eventName, custumParameters);
  }
};

export const arExperienceLaunchEventLog = (
  eventName,
  custumParameters = {}
) => {
  if (eventName.length > 0) {
    logEvent(analytics, eventName, custumParameters);
  }
};

// debounce event
export const clickEventLogDebounced = debounce(clickEventLog, 1000);
export const arExperienceLaunchEventLogDebounced = debounce(
  arExperienceLaunchEventLog,
  1000
);
export const pageViewEventLogDebounced = debounce(pageViewEventLog, 1000);
export const errorStateEventLogDebounced = debounce(erroStateEventLog, 1000);
