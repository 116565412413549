import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import arrowIcon from "assets/icons/arrow-icon.svg";
import PropTypes from "prop-types";

const ArrowButton = ({ direction, isDisabled, clickCallback, ...props }) => {
  useEffect(() => {
    return () => {};
  }, []);

  const getStyle = () => {
    if (direction === "right") {
      return { right: 0 };
    } else {
      return { left: 0 };
    }
  };

  const clickHandler = () => {
    if (clickCallback) {
      clickCallback(direction);
    }
  };

  return (
    <Button
      disableRipple
      disabled={isDisabled}
      variant="arrowBtn"
      sx={getStyle()}
      direction="column"
      onClick={clickHandler}
      {...props}
    >
      <Box
        component="img"
        width={{ xs: "12px", md: "18px" }}
        height={{ xs: "20px", md: "28px" }}
        sx={{
          objectFit: "contain",
          opacity: isDisabled ? "0.43" : "1",
          transform: direction === "right" ? "scaleX(-1)" : "none",
        }}
        src={arrowIcon}
        alt="arrow icon"
      />
    </Button>
  );
};

ArrowButton.propTypes = {
  direction: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  clickCallback: PropTypes.func,
};

ArrowButton.defaultProps = {
  direction: "left",
  isDisabled: false,
  clickCallback: null,
};

export default ArrowButton;
