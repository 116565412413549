import { isFirefox, browserName, isIOS, isMobile } from "react-device-detect";

export const checkIsARAvailable = () => {
  let isOpera = browserName.toLowerCase().includes("opera");
  // is mobile && is NOT firefox && xr feature available || isIOS
  if (
    isMobile &&
    !isFirefox &&
    !isOpera &&
    ((window.navigator.xr &&
      window.navigator.xr.isSessionSupported("immersive-ar")) ||
      isIOS)
  ) {
    return true;
  }
  return false;
};
