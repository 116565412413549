import React, { forwardRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Tabs, Tab } from "@mui/material";
import {
  pageViewEventLogDebounced,
  PellaAnalyticsTag,
} from "utils/analyticsManager";

const TabsController = forwardRef(
  (
    {
      id,
      options,
      selectedType,
      selectionCallback,
      justifyStyle,
      variant,
      typeKey,
      nameKey,
      ...props
    },
    ref
  ) => {
    const handleChange = (event, newValue) => {
      if (selectionCallback) {
        selectionCallback(newValue);
      }
    };
    useEffect(() => {
      const selectedTab = options[selectedType];
      if (selectedTab) {
        const tabName = selectedTab[nameKey];
        pageViewEventLogDebounced(
          PellaAnalyticsTag.FILTER_TAB_SELECT + `${tabName.toLowerCase()}`
        );
      }
    }, [options, selectedType, nameKey]);

    return (
      <Tabs
        ref={ref}
        value={selectedType}
        onChange={handleChange}
        textColor="inherit"
        aria-label={`${id} tab control`}
        sx={{
          color: variant === "light" ? "tabslight.light" : "tabsdark.light",
          "& .Mui-selected": {
            color: variant === "light" ? "tabslight.main" : "tabsdark.main",
          },
          "& .MuiTabs-indicator": { height: "4px" },
          "& .MuiTabs-scroller": {
            "& .MuiTabs-flexContainer": {
              justifyContent: justifyStyle,
            },
          },
        }}
        {...props}
      >
        {options.length > 0 &&
          options.map((category, index) => {
            const type = category[typeKey] ?? "";
            const name = category[nameKey] ?? "";
            return (
              <Tab
                variant={variant}
                key={`${id}_${type + "_" + index}`}
                id={`${id}-tab-${index}`}
                aria-controls={`${id}-tabpanel-${index}`}
                disableRipple
                label={name}
              />
            );
          })}
      </Tabs>
    );
  }
);

TabsController.propTypes = {
  id: PropTypes.string,
  variant: PropTypes.string,
  options: PropTypes.array,
  selectedType: PropTypes.number,
  justifyStyle: PropTypes.string,
  typeKey: PropTypes.string,
  nameKey: PropTypes.string,
  selectionCallback: PropTypes.func,
};

TabsController.defaultProps = {
  id: "product",
  variant: "light",
  options: [],
  selectedType: 0,
  justifyStyle: "flex-start",
  typeKey: "product_type",
  nameKey: "product_name",
  selectionCallback: null,
};

export default TabsController;
