import React, { useState, useEffect, useRef, useContext } from "react";
import { Stack, Typography, Button } from "@mui/material";
import { DevicePropertyContext } from "context/devicePropertyContext";
import { ProductFilterContext } from "context/productFilterContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as ArIcon } from "assets/icons/aricon-black.svg";
import ErrorStateView from "../errorStateView";
import { browserName } from "react-device-detect";
import {
  PellaAnalyticsTag,
  arExperienceLaunchEventLogDebounced,
  errorStateEventLogDebounced,
} from "utils/analyticsManager";

function ModelViewerContainer({ ...props }) {
  const modelRef = useRef();
  const [modelSrcUrl, setModelSrcUrl] = useState("");
  const [loadError, setLoadError] = useState(false);
  const placementType = "floor";
  const { state, productType, finalAssetUrl } =
    useContext(ProductFilterContext);
  const { isARAvailable, isLandscape } = useContext(DevicePropertyContext);
  const [containerOpacity, setContainerOpacity] = useState(0);
  const isSmallWlDevice = useMediaQuery("(max-width:362px)");
  const isSmallHlDevice = useMediaQuery("(max-height:362px)");

  useEffect(() => {
    setContainerOpacity(0);
    if (modelRef.current) {
      modelRef.current.addEventListener("model-visibility", function (e) {
        setContainerOpacity(1);
      });

      modelRef.current.addEventListener("load", function (e) {
        setLoadError(false);
        setContainerOpacity(1);
      });

      modelRef.current.addEventListener("error", function (e) {
        setLoadError(true);
        setContainerOpacity(1);
      });
    }
  }, []);

  useEffect(() => {
    setModelSrcUrl(finalAssetUrl?.length === 0 ? "" : finalAssetUrl);
  }, [finalAssetUrl]);

  const adjustVHCameraTarget = () => {
    if (isSmallHlDevice || isSmallWlDevice) {
      return productType === 0 ? "0.45m" : "0.65m";
    } else {
      return productType === 0 ? "0.55m" : "0.8m";
    }
  };

  const displayLoadError = () => {
    if (modelSrcUrl.length >= 0 && loadError) {
      errorStateEventLogDebounced(PellaAnalyticsTag.AR_ASSET_LOAD_ERROR, {
        asset_url: modelSrcUrl,
        browser_name: browserName,
      });
      return (
        <ErrorStateView
          width="100%"
          height="100%"
          errorMessage="Please select another option."
        />
      );
    }
    return null;
  };
  const handleLaunchAR = () => {
    const eventPayload = {
      browser_name: browserName, // Make sure to define and assign a value to browserName
      product_type: state.products[productType]?.productType,
      shape: state.products[productType]?.options.find(
        (option) => option.filterId === "sh"
      )?.selectedName,
      color: state.products[productType]?.options.find(
        (option) => option.filterId === "c"
      )?.selectedName,
      grille: state.products[productType]?.options.find(
        (option) => option.filterId === "g"
      )?.selectedName,
      size: state.products[productType]?.options.find(
        (option) => option.filterId === "s"
      )?.selectedName,
    };
    arExperienceLaunchEventLogDebounced(
      PellaAnalyticsTag.AR_LAUNCH_EVENT,
      eventPayload
    );
  };

  const getModelViewer = () => {
    if (isARAvailable) {
      return (
        <model-viewer
          className="viewer"
          id="pella-window"
          src={modelSrcUrl}
          ar
          ar-modes="webxr scene-viewer quick-look"
          ar-placement={placementType}
          camera-controls
          disable-pan
          disable-zoom
          shadow-intensity="0.25"
          min-field-of-view="32deg"
          alt=""
          camera-target={`auto ${adjustVHCameraTarget()} auto`}
          ref={(ref) => {
            modelRef.current = ref;
          }}
          style={{
            width: "100%",
            height: isLandscape ? "90%" : "100%",
            position: "relative",
            opacity: containerOpacity,
            transition: "opacity 0.3s",
          }}
        >
          {displayLoadError()}
          <Stack alignItems="center" slot="ar-button">
            <Button
              variant="light"
              disableRipple
              disabled={modelSrcUrl.length >= 0 && loadError}
              style={{
                minWidth: "196px",
                position: "absolute",
                bottom: isLandscape ? (isSmallHlDevice ? "0%" : "6%") : "4%",
                zIndex: 2,
              }}
              startIcon={
                <ArIcon
                  style={{
                    opacity: modelSrcUrl.length >= 0 && loadError ? 0.55 : 1,
                  }}
                />
              }
              onClick={handleLaunchAR}
            >
              LAUNCH IN AR
            </Button>
          </Stack>
        </model-viewer>
      );
    } else {
      return (
        <>
          <model-viewer
            className="viewer"
            id="pella-window"
            src={modelSrcUrl}
            camera-controls
            disable-pan
            disable-zoom
            shadow-intensity="0.25"
            field-of-view="32deg"
            alt=""
            ref={(ref) => {
              modelRef.current = ref;
            }}
            style={{ width: "100%", height: "90%" }}
          >
            {displayLoadError()}
          </model-viewer>
          <Stack
            pb={2}
            textAlign="center"
            alignItems="center"
            justifyContent="center"
          >
            <Typography maxWidth="90%" variant="italicBody2" px={2}>
              To launch the AR experience, please open this page in the
              following mobile browsers: Safari, Chrome or Microsoft Edge.
            </Typography>
          </Stack>
        </>
      );
    }
  };

  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      {...props}
      alignItems="center"
      justifyContent="center"
    >
      <Stack
        width="100%"
        height="100%"
        p={2}
        sx={{ opacity: containerOpacity, transition: "opacity 0.3s" }}
      >
        {getModelViewer()}
      </Stack>
    </Stack>
  );
}

export default ModelViewerContainer;
