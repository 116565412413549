import { useContext, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  Avatar,
  useMediaQuery,
  styled,
  useTheme,
} from "@mui/material";
import { DevicePropertyContext } from "context/devicePropertyContext";
import GlobalStyles from "@mui/material/GlobalStyles";
import arIcon from "assets/icons/aricon.svg";
import TabletPortrait from "assets/images/TabletPortrait.png";
import MobilePortrait from "assets/images/MobilePortrait.png";
import MobileLandscape from "assets/images/MobileLandscape.png";
import TabletLandscape from "assets/images/TabletLandscape.png";
import {
  pageViewEventLogDebounced,
  clickEventLog,
  PellaAnalyticsTag,
} from "utils/analyticsManager";
import { browserName } from "react-device-detect";

const Homepage = (props) => {
  const { isARAvailable } = useContext(DevicePropertyContext);
  const isMobilePortrait = useMediaQuery(
    "(max-width: 390px) and (orientation: portrait)"
  );
  const isPortrait = useMediaQuery(
    "(max-width: 767px) and (orientation: portrait)"
  );
  const isMobileLandscape = useMediaQuery(
    "(max-width: 991px) and (orientation: landscape)"
  );
  const isTabletPortrait = useMediaQuery(
    "(max-width: 1133px) and (orientation: portrait)"
  );
  const isTabletLandscape = useMediaQuery(
    "(max-width: 1199px) and (orientation: landscape)"
  );
  const flexDirection = isPortrait || isTabletPortrait ? "column" : "row";
  const CustomAvatar = styled(Avatar)`
    .MuiAvatar-img {
      width: 28px;
      height: 28px;
    }
  `;
  const newImageSrc = isMobilePortrait
    ? MobilePortrait
    : isPortrait
    ? TabletPortrait
    : isMobileLandscape
    ? MobileLandscape
    : isTabletPortrait
    ? TabletPortrait
    : isTabletLandscape
    ? TabletLandscape
    : TabletPortrait;
  const theme = useTheme();

  useEffect(() => {
    pageViewEventLogDebounced(
      PellaAnalyticsTag.HOME,
      isARAvailable,
      browserName
    );
  });
  const handleTryNowClick = () => {
    clickEventLog(PellaAnalyticsTag.HOME_START_CTA_CLICK, { browserName }); // Track the "Try Now" button click event with the browser name
  };

  const handlePellaLinkClick = () => {
    clickEventLog(PellaAnalyticsTag.HOME_PELLA_EXIT_CTA_CLICK, { browserName }); // Track the "pella.com" link click event with the browser name
  };

  const getFooterLink = () => {
    return (
      <Typography variant="body2">
        Please note: Additional styles available on
        <Typography
          component="a"
          href="https://www.pella.com/"
          sx={{
            color: theme.palette.landingPage.dark,
            fontSize: "12px !important",
            marginLeft: 0.5,
          }}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handlePellaLinkClick}
        >
          Pella.com
        </Typography>
        .
      </Typography>
    );
  };

  return (
    <Box
      component="section"
      sx={{
        height: "100%",
      }}
    >
      <GlobalStyles
        styles={{
          html: { height: "100%" },
          body: {
            height: "100%",
            backgroundColor: theme.palette.landingPage.bodyBackground,
            overflow: "hidden",
          },
          "#root": { height: "100%" },
        }}
      />
      <Container
        sx={{
          height: "100%",
          paddingLeft: isPortrait || isTabletPortrait ? 0 : 1,
          paddingRight: isPortrait || isTabletPortrait ? 0 : 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: { flexDirection },
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              flex: isPortrait || isTabletPortrait ? "20%" : "50%",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "center",
              paddingTop: isPortrait || isTabletPortrait ? 7 : 0,
              paddingBottom: isPortrait || isTabletPortrait ? 4 : 0,
            }}
          >
            <Box
              sx={{
                flex: isPortrait || isTabletPortrait ? "0" : "1",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  marginBottom: 1.5,
                  fontSize: isMobileLandscape ? "28px !important" : "28px",
                }}
              >
                Welcome to the Pella
                <br /> AR experience.
              </Typography>
              <Typography variant="body1" sx={{ fontSize: "16px !important" }}>
                Imagine new windows and patio doors
                <br /> in your own home.
              </Typography>
              {!(isPortrait || isTabletPortrait) && (
                <Button
                  href="/ar"
                  disableRipple
                  variant="dark"
                  sx={{ marginTop: 4, textTransform: "uppercase" }}
                  startIcon={isARAvailable && <CustomAvatar src={arIcon} />}
                  onClick={handleTryNowClick}
                >
                  Try it now
                </Button>
              )}
            </Box>
            {!(isPortrait || isTabletPortrait) && (
              <Box
                sx={{
                  flex: "0",
                  paddingBottom: 4,
                }}
              >
                {getFooterLink()}
              </Box>
            )}
          </Box>
          <Box
            sx={{
              flex: isPortrait ? "80%" : "50%",
              display: "flex",
              justifyContent: "center",
              lineHeight: "0",
              position: "relative",
              alignSelf: "flex-end",
              width: isPortrait || isTabletPortrait ? "100%" : "auto",
            }}
          >
            {!isARAvailable && (
              <Box
                sx={{
                  backgroundColor: theme.palette.landingPage.transparent75,
                  p: 1.5,
                  borderRadius: 1,
                  mb: 2,
                  color: theme.palette.landingPage.light,
                  zIndex: "1",
                  position:
                    isPortrait || isTabletPortrait ? "fixed" : "absolute",
                  right: "16px",
                  left: "16px",
                  bottom: isPortrait || isTabletPortrait ? "118px" : "32px",
                  textAlign: "center",
                }}
              >
                {
                  <Typography variant="body2">
                    For the full AR experience, please use one of
                    {!isMobileLandscape && <br />} the following browsers on
                    your mobile device:
                    {!isMobileLandscape && <br />}
                    <span>Safari | Chrome | Microsoft Edge</span>
                  </Typography>
                }
              </Box>
            )}

            {isPortrait || isTabletPortrait ? (
              <Box
                sx={{
                  position: "fixed",
                  bottom: "24px",
                  zIndex: "9",
                  width: "100%",
                  textAlign: "center",
                  paddingLeft: isPortrait || isTabletPortrait ? 2 : 0,
                  paddingRight: isPortrait || isTabletPortrait ? 2 : 0,
                }}
              >
                <Button
                  href="/ar"
                  disableRipple
                  variant="dark"
                  sx={{
                    width: "100%",
                    marginBottom: 2,
                    textTransform: "uppercase",
                  }}
                  startIcon={isARAvailable && <CustomAvatar src={arIcon} />}
                  onClick={handleTryNowClick}
                >
                  Try it now
                </Button>
                {getFooterLink()}
              </Box>
            ) : null}
            <img
              src={newImageSrc}
              style={{
                maxWidth: "100%",
                height: isPortrait || isTabletPortrait ? "100%" : "90vh",
                objectFit: "contain",
                objectPosition: "bottom",
              }}
              alt="Hero"
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Homepage;
