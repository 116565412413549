import React, { useState, useContext, useRef, forwardRef } from "react";
import PropTypes from "prop-types";
import { Divider, Stack } from "@mui/material";
import { TabPanel, TabContext } from "@mui/lab";
import { DevicePropertyContext } from "context/devicePropertyContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import FilterOptionCarousel from "../filterCarousel";
import TabsController from "../tabsController";
import {
  ProductFilterContext,
  ProductFilterActionTypes,
} from "context/productFilterContext";
const FiltersContainer = forwardRef(
  ({ data, stateFilterOptions, ...props }, ref) => {
    const tabsRef = useRef();
    const [filterType, setFilterType] = useState(0);
    const { dispatch, matchedShapeProduct } = useContext(ProductFilterContext);
    const { isLandscape } = useContext(DevicePropertyContext);
    const isXSmallDevice = useMediaQuery("(max-width:360px)");
    const isSmallDevice = useMediaQuery("(max-width:638px)");

    const handleFilterTypeChange = (newValue) => {
      setFilterType(newValue);
    };

    const updateFilterOptionSelection = (filterId, selectedId, productName) => {
      dispatch({
        type: ProductFilterActionTypes.CHANGE_FILTER_OPTION,
        payload: {
          filterId: filterId,
          selectedId: selectedId,
          selectedName: productName,
        },
      });
    };

    const getPreslectedId = (filterId) => {
      let matched = stateFilterOptions.filter((o) => {
        return o.filterId === filterId;
      })[0];
      return matched?.selectedId ?? 0;
    };

    return (
      <Stack
        ref={ref}
        direction="column"
        position="relative"
        px={isLandscape ? (isSmallDevice ? 0 : 2) : 0}
        pb={isLandscape ? (isSmallDevice ? 0 : "4%") : 0}
        alignItems="center"
        {...props}
      >
        <Stack
          data-testid="filters-container"
          justifyContent="flex-start"
          width="100%"
          height="fit-content"
          maxWidth={isLandscape ? (isSmallDevice ? "none" : "600px") : "none"}
          sx={{
            backgroundColor: "#fff",
            boxShadow: [0, 1],
          }}
        >
          <TabContext value={`${filterType}`}>
            <TabsController
              ref={tabsRef}
              data-testid="filter-tabs-control"
              id="filter"
              variant="dark"
              options={data.categories}
              selectedType={filterType}
              selectionCallback={handleFilterTypeChange}
              width="100%"
              justifyStyle={isXSmallDevice ? "space-between" : "space-evenly"}
              typeKey="attribute_name"
              nameKey="value"
            />
            <Divider width="100%" />
            {stateFilterOptions &&
              data.categories.map((option, index) => {
                let filterId = option.filter_id;
                let availableOptions = option.options;
                if (matchedShapeProduct && filterId !== "sh") {
                  availableOptions = matchedShapeProduct[filterId];
                }
                return (
                  <TabPanel
                    data-testid="filter-tab-panels"
                    key={"filter-tab-panels-" + index}
                    index={index}
                    value={`${index}`}
                    sx={{
                      padding: "0",
                      position: "relative",
                    }}
                  >
                    <FilterOptionCarousel
                      filterId={option.filter_id}
                      preSelectedId={getPreslectedId(option.filter_id)}
                      availableOptions={availableOptions}
                      data={option.options}
                      selectionCallback={updateFilterOptionSelection}
                    />
                  </TabPanel>
                );
              })}
          </TabContext>
        </Stack>
      </Stack>
    );
  }
);

FiltersContainer.propTypes = {
  data: PropTypes.object,
  stateFilterOptions: PropTypes.array,
};

FiltersContainer.defaultProps = {
  data: null,
  stateFilterOptions: [],
};

export default FiltersContainer;
