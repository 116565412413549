import React from "react";
import PropTypes from "prop-types";
import { Stack, Button, Typography, Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import checkmarkIcon from "assets/icons/selected_checkmark.svg";

const FilterOption = ({
  filterId,
  id,
  selected,
  isDisabled,
  data,
  clickCallback,
  ...props
}) => {
  const isColorSwatch = filterId === "c";
  const betweenSmallTabletDevice = useMediaQuery("(max-width:760px)");

  const clickHandler = (e) => {
    if (clickCallback) {
      // backend is not returning id start from 0 like loop, so we make sure we use the actual id return from BE
      // in order to find the match item
      clickCallback(data.id, data.attribute_name);
    }
  };

  const getImgSrcSet = () => {
    if (data.thumbnail_2x) {
      return `${data.thumbnail}, ${data.thumbnail_2x} 2x`;
    } else {
      return `${data.thumbnail}`;
    }
  };

  return (
    <Button
      disableRipple
      data-testid="filter-option-button"
      sx={{
        display: isDisabled ? "none" : "default",
        py: 0,
        textTransform: "none",
        width: "fit-content",
        "&:hover": { backgroundColor: "transparent" },
      }}
      onClick={clickHandler}
    >
      <Stack
        direction="column"
        width="fit-content"
        alignItems="center"
        justifyContent="center"
        color="transparent"
        maxWidth={
          isColorSwatch
            ? { xs: "65px", sm: betweenSmallTabletDevice ? "65px" : "none" }
            : "none"
        }
        pt={isColorSwatch ? 3 : 1}
      >
        <Box
          position="relative"
          data-testid="filter-option-highlite-box"
          height={
            isColorSwatch
              ? { xs: "65px", sm: betweenSmallTabletDevice ? "65px" : "92px" }
              : {
                  xs: "90px",
                  sm: betweenSmallTabletDevice ? "90px" : "130px",
                }
          }
          borderRadius={isColorSwatch ? "50%" : "0"}
          overflow="visible"
          border="2px solid"
          sx={{
            minWidth: isColorSwatch
              ? { xs: "65px", sm: betweenSmallTabletDevice ? "65px" : "92px" }
              : "80px",
            borderColor: selected ? "primary.main" : "transparent",
            backgroundColor: selected ? "primary.light" : "transparent",
            boxShadow: selected ? "0px 3px 12px rgba(0, 0, 0, 0.12)" : "none",
            transition:
              "background-color 0.3s,border-color 0.3s, box-shadow 0.3s",
          }}
        >
          <img
            src={checkmarkIcon}
            alt="check mark icon"
            data-testid="filter-option-checkmark-icon"
            style={{
              visibility: selected ? "visible" : "hidden",
              opacity: selected ? 1 : 0,
              width: betweenSmallTabletDevice ? "24px" : "32px",
              height: betweenSmallTabletDevice ? "24px" : "32px",
              objectFit: "contain",
              position: "absolute",
              top: isColorSwatch ? "-5px" : "-10px",
              right: isColorSwatch ? "-5px" : "-10px",
              transition: "opacity 0.3s",
            }}
          />
          <Box
            width="100%"
            height="100%"
            borderRadius={isColorSwatch ? "50%" : "0"}
            overflow="hidden"
            border={
              isColorSwatch ? { xs: "4px solid", sm: "6px solid" } : "none"
            }
            borderColor="white"
          >
            <img
              srcSet={getImgSrcSet()}
              src={`${data.thumbnail}`}
              alt="Elva dressed as a fairy"
              style={{ height: "100%", objectFit: "cover" }}
            />
          </Box>
        </Box>

        <Typography
          variant="body2"
          data-testid="filter-option-label"
          color="black"
          mt={1}
          mb={0.5}
          sx={{
            whiteSpace: isColorSwatch ? "nowrap" : "normal",
            maxWidth: isColorSwatch ? "none" : "100px",
            maxHeight: "36px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
            visibility: selected ? "visible" : "hidden",
            opacity: selected ? 1 : 0,
            transition: "0.3s",
          }}
        >
          {data.attribute_name}
        </Typography>
      </Stack>
    </Button>
  );
};

FilterOption.propTypes = {
  filterId: PropTypes.string,
  id: PropTypes.number,
  selected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  data: PropTypes.object,
  clickCallback: PropTypes.func,
};

FilterOption.defaultProps = {
  filterId: "",
  id: 0,
  selected: false,
  isDisabled: false,
  data: null,
  clickCallback: null,
};

export default FilterOption;
