import { breakpoints } from "./breakpoints";
import { color } from "./color";

export const tabs = {
  styleOverrides: {
    root: {
      bkitTapHighlightColor: "transparent",
      fontFamily: "Gantari-SemiBold",
      fontSize: "1rem",
      lineHeight: "140%",
      "& .MuiTabs-indicator": { height: "4px" },
      "& .MuiTab-root": { textTransform: "none" },
      [`@media screen and (min-width: ${breakpoints.values.sm}px)`]: {
        fontSize: "1.125rem",
      },
    },
  },
};

export const tab = {
  variants: [
    {
      props: { variant: "dark" },
      style: {
        color: color.tabsdark.light,
        "& .Mui-selected": {
          fontWeight: 600,
          color: color.tabsdark.main,
        },
      },
    },
    {
      props: { variant: "light" },
      style: {
        color: color.tabslight.light,
        "& .Mui-selected": {
          fontWeight: 600,
          color: color.tabslight.main,
        },
      },
    },
  ],
};
