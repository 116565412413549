import {
  Stack,
  Icon,
  CircularProgress,
  useMediaQuery,
  Box,
} from "@mui/material";
import React from "react";
import logo from "assets/icons/logo.svg";

const Loader = (props) => {
  const isSmallDevice = useMediaQuery("(max-width:640px)");

  return (
    <Stack
      width="100vw"
      height="92vh"
      position="absolute"
      zIndex={1}
      alignItems="center"
      justifyContent="center"
      backgroundColor="tabslight.transparent95"
      sx={{
        opacity: props.opacity,
        transition: "opacity 0.3s",
      }}
    >
      <Box position="relative">
        <CircularProgress
          variant="determinate"
          size={isSmallDevice ? 120 : 160}
          sx={{
            color: "gray.main",
          }}
          thickness={1.5}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          size={isSmallDevice ? 120 : 160}
          thickness={1.5}
          sx={{ position: "absolute", top: 0, left: 0 }}
        />
        <Icon
          sx={{
            width: isSmallDevice ? "100px" : "140px",
            height: isSmallDevice ? "100px" : "140px",
            position: "absolute",
            top: "10px",
            left: "10px",
          }}
        >
          <img
            src={logo}
            className="logo-icon"
            alt="pella logo"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </Icon>
      </Box>
    </Stack>
  );
};

export default Loader;
