import React, { useState, useEffect, useRef } from "react";
import { Stack } from "@mui/material";
import FilterOption from "components/atoms/filterOption";
import ArrowButton from "components/atoms/arrowButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";

const FilterOptionCarousel = ({
  filterId,
  preSelectedId,
  data,
  availableOptions,
  selectionCallback,
  ...props
}) => {
  const [alignment, setAlignment] = useState("flex-start");
  const [showArrow, setShowArrow] = useState(false);
  const scrollerRef = useRef();
  const listRef = useRef();
  const [opacity, setOpacity] = useState(0);
  const [selectedId, setSelectedId] = useState(preSelectedId);
  const [currentPage, setCurrentPage] = useState(0);
  const [leftArrowDisabled, setLeftArrowDisabled] = useState(true);
  const [rightArrowDisabled, setRightArrowDisabled] = useState(false);
  const betweenSmallTabletDevice = useMediaQuery("(max-width:760px)");

  const adjustAligment = () => {
    if (scrollerRef.current.clientWidth >= listRef.current.clientWidth) {
      setAlignment("center");
      setShowArrow(false);
    } else {
      setAlignment("flex-start");
      setShowArrow(true);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", adjustAligment);
    window.addEventListener("orientationchange", adjustAligment);
    setTimeout(() => {
      adjustAligment();
      setOpacity(1);
      setCurrentPage(0);
    }, 50);

    return () => {
      window.removeEventListener("resize", adjustAligment);
      window.removeEventListener("orientationchange", adjustAligment);
    };
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectedId(preSelectedId);
  }, [preSelectedId]);

  useEffect(() => {
    let x = 80 * (selectedId - (data[0]?.id ?? 0));
    scrollerRef.current.scrollLeft = x;
  }, [selectedId]); // eslint-disable-line react-hooks/exhaustive-deps

  const clickHandler = (id, productName) => {
    setSelectedId(id);
    if (selectionCallback) {
      selectionCallback(filterId, id, productName);
    }
  };

  const scrollClickHandler = (direction) => {
    let pages = Math.round(
      listRef.current.clientWidth / scrollerRef.current.clientWidth
    );
    let currentP = 0;
    if (direction === "right") {
      if (currentPage < pages) {
        currentP = currentPage + 1;
        setCurrentPage(currentP);
      }
    } else {
      if (currentPage > 0) {
        currentP = currentPage - 1;
        setCurrentPage(currentP);
      }
    }
    scrollerRef.current.scrollLeft = scrollerRef.current.clientWidth * currentP;
    arrowDisplayLogic();
  };

  const arrowDisplayLogic = () => {
    setLeftArrowDisabled(scrollerRef.current.scrollLeft <= 0);
    setRightArrowDisabled(
      scrollerRef.current.scrollLeft + scrollerRef.current.clientWidth >=
        listRef.current.clientWidth
    );
  };

  return (
    <>
      {showArrow && (
        <>
          <ArrowButton
            direction="left"
            data-testid="arrow-button-left"
            isDisabled={leftArrowDisabled}
            clickCallback={scrollClickHandler}
          />
          <ArrowButton
            direction="right"
            data-testid="arrow-button-right"
            isDisabled={rightArrowDisabled}
            clickCallback={scrollClickHandler}
          />
        </>
      )}
      <Stack
        ref={scrollerRef}
        data-testid="filter-option-carousel"
        direction="row"
        position="relative"
        {...props}
        // have to have heigh defined here, otherwise the content will be
        // jumping up or down when switch different filter categories
        height={{
          xs: "136px",
          sm: betweenSmallTabletDevice ? "136px" : "178px",
        }}
        justifyContent={alignment}
        onScroll={arrowDisplayLogic}
        sx={{
          my: 3,
          mx: showArrow ? { xs: 4.5, md: 7 } : 3,
          px: { xs: 2, md: 4 },
          opacity: `${opacity}`,
          overflowX: alignment === "center" ? "visible" : "auto",
          scrollBehavior: "smooth",
          transition: "opacity 0.5s, scrollbarColor 0.3s",
          scrollbarColor: "transparent transparent",
          scrollbarWidth: "thin",
          "::-webkit-scrollbar": {
            height: "2px",
            background: "transparent",
          },
          "::-webkit-scrollbar-thumb": {
            height: "2px",
            backgroundColor: "transparent",
            transition: "backgroundColor 0.3s",
          },
          "&:hover": {
            scrollbarColor: "#E6E7E8 transparent",
            scrollbarWidth: "thin",
            "::-webkit-scrollbar": {
              height: "2px",
              background: "transparent",
            },
            "::-webkit-scrollbar-thumb": {
              height: "2px",
              backgroundColor: "gray.main",
              transition: "backgroundColor 0.3s",
            },
          },
        }}
      >
        <Stack
          ref={listRef}
          direction="row"
          width="fit-content"
          data-testid="filter-option-scroll-content"
          alignItems="flex-start"
        >
          {data.map((item, index) => {
            let found = availableOptions.find((o) => {
              return o.id === item.id;
            });
            let isDisabled = found ? false : true;
            return (
              <FilterOption
                filterId={filterId}
                isDisabled={isDisabled}
                selected={selectedId === item.id}
                id={index}
                key={"filter-option-" + item.id}
                data={item}
                clickCallback={clickHandler}
              />
            );
          })}
        </Stack>
      </Stack>
    </>
  );
};

FilterOptionCarousel.propTypes = {
  filterId: PropTypes.string.isRequired,
  preSelectedId: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  availableOptions: PropTypes.array,
  selectionCallback: PropTypes.func,
};

FilterOptionCarousel.defaultProps = {
  filterId: "",
  preSelectedId: 0,
  data: [],
  availableOptions: [],
  selectionCallback: null,
};

export default FilterOptionCarousel;
