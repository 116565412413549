import React, {
  createContext,
  useEffect,
  useState,
  useReducer,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import { filter } from "lodash";
import { browserName } from "react-device-detect";
import {
  clickEventLogDebounced,
  PellaAnalyticsTag,
} from "utils/analyticsManager";
import {
  getFilteredCatelogGroup,
  findMatchedFilteredItem,
  getMatchedCatalogShape,
  checkFilterSelectedIdAvailable,
} from "utils/catalogHelper";

export const ProductFilterContext = createContext();

export const ProductFilterActionTypes = {
  CONFIGURE_INITIAL_PRODUCT_TYPE: "configureInitialProductType",
  CHANGE_PRODUCT_TYPE: "changeProductType",
  CHANGE_FILTER_OPTION: "changeFilterOption",
};

export const ProductFilterWrapper = ({ children }) => {
  const initalState = { products: [] };
  const [filteredCatalog, setFilteredCatalog] = useState(null);
  const [finalAssetUrl, setFinalAssetUrl] = useState("");
  const [matchedShapeProduct, setMatchedShapeProduct] = useState(null);
  const [readyToRender, setReadyToRender] = useState(false);

  const updateFilterType = (state, productType, payload) => {
    let products = state.products;
    let mappedProduct = products[productType];
    let typeName = mappedProduct?.productType;
    let mappedProductOptions = mappedProduct?.options;
    // always find the current selected shape option in state, because the logic is shape driven,
    // this is for payload that's not shape filter option lookup
    let shapeOption = filter(mappedProductOptions, (o) => {
      return o.filterId === "sh";
    })[0];
    // for payload shape option update, replace shapeOption with payload selectedId and selectedName
    // in order to find right matched catalog, otherwise will use old state shape selectedName
    if (payload?.filterId === "sh") {
      shapeOption.selectedName =
        payload?.selectedName ?? shapeOption?.selectedName;
    }
    // find the shape catalog object
    let matchedCatalogShape = getMatchedCatalogShape(
      filteredCatalog,
      typeName,
      shapeOption?.selectedName
    );
    // set matched shape product for UI to reference on which filter option should be hidden
    setMatchedShapeProduct(matchedCatalogShape);
    // replace state's currentProduct option selections with payload.selectedId and selectedName
    // also check against catalog to see if the selected filter option is available, if it's not available
    // default to use catalog's first available option as selectedId
    if (mappedProduct && matchedCatalogShape) {
      mappedProduct.options = mappedProduct.options.map((option) => {
        let modifiedOption = option;
        if (payload?.filterId === option.filterId) {
          modifiedOption.selectedId = payload.selectedId;
          modifiedOption.selectedName = payload.selectedName;
        } else {
          if (option.filterId !== "sh") {
            // if it's not shape filter
            // check against catalog to see if the selected filter option is available, if it's not available
            // default to use catalog's first available option as selectedId
            let availableObj = checkFilterSelectedIdAvailable(
              matchedCatalogShape,
              option.filterId,
              option.selectedId,
              option.selectedName
            );
            modifiedOption.selectedId = availableObj.selectedId;
            modifiedOption.selectedName = availableObj.selectedName;
          }
        }
        return modifiedOption;
      });
      // replace state products item with new mapped currentProduct
      products.map(
        (product) =>
          [mappedProduct].find(
            (mappedProduct) => mappedProduct.filterId === product.filterId
          ) || product
      );
      return products;
    } else {
      return state.products;
    }
  };
  const reducer = (state, action) => {
    let payload = action.payload;
    switch (action.type) {
      case ProductFilterActionTypes.CONFIGURE_INITIAL_PRODUCT_TYPE:
        return {
          ...state,
          products: updateFilterType(payload, productType, payload),
        };
      case ProductFilterActionTypes.CHANGE_PRODUCT_TYPE:
      case ProductFilterActionTypes.CHANGE_FILTER_OPTION: {
        return {
          ...state,
          products: updateFilterType(state, productType, payload),
        };
      }
      default:
        return state;
    }
  };
  const [productData, setProductData] = useState(null);
  const [productType, setProductType] = useState(0);
  const [catalogData, setCatalogData] = useState(null);

  const [state, dispatch] = useReducer(reducer, initalState);

  useEffect(() => {
    if (state.products.length > 0) {
      const getMatchedModel = (products, productType) => {
        let typeName = products[productType]?.productType;
        let options = products[productType]?.options;
        let assetName = findMatchedFilteredItem(
          filteredCatalog,
          options,
          typeName
        );
        setFinalAssetUrl(assetName);
      };
      getMatchedModel(state.products, productType);
      const eventPayload = {
        browser_name: browserName,
        product_type: state.products[productType]?.productType,
        shape: state.products[productType]?.options.find(
          (option) => option.filterId === "sh"
        )?.selectedName,
        color: state.products[productType]?.options.find(
          (option) => option.filterId === "c"
        )?.selectedName,
        grille: state.products[productType]?.options.find(
          (option) => option.filterId === "g"
        )?.selectedName,
        size: state.products[productType]?.options.find(
          (option) => option.filterId === "s"
        )?.selectedName,
      };
      clickEventLogDebounced(
        PellaAnalyticsTag.AR_CONFIGURATION_CHANGE,
        eventPayload
      );
    }
  }, [state, productType, filteredCatalog]);

  useEffect(() => {
    // create state products property
    if (productData && productData.products && catalogData) {
      if (catalogData.length > 0) {
        let catalog = getFilteredCatelogGroup(catalogData, productData);
        setFilteredCatalog(catalog);
      }
      let typeList = [];
      let initialShape = "";
      let initialId = 0;
      productData.products.forEach((product, index) => {
        let optionList = [];
        product.categories.forEach((category) => {
          if (index === 0 && category.filter_id === "sh") {
            initialId = category.options[0].id;
            initialShape = category.options[0].attribute_name;
          }
          let optionObj = {
            filterId: category.filter_id,
            priority: category.id,
            selectedId: category.options[0].id,
            selectedName: category.options[0].attribute_name,
          };
          optionList.push(optionObj);
        });
        optionList.sort((a, b) => {
          return a.priority - b.priority;
        });
        let typeObj = {
          productType: product.product_type,
          options: optionList,
        };
        typeList.push(typeObj);
      });
      // console.log(typeList);
      // console.log(initialShape);
      // payload also expected to pass first shape id and name to dispatch type ProductFilterActionTypes.CONFIGURE_INITIAL_PRODUCT_TYPE
      dispatch({
        type: ProductFilterActionTypes.CONFIGURE_INITIAL_PRODUCT_TYPE,
        payload: {
          products: typeList,
          filterId: "sh", // shape driven filter
          selectedId: initialId,
          selectedName: initialShape,
        },
      });
      setReadyToRender(true);
    }
  }, [productData, catalogData]);

  const providerValue = useMemo(
    () => ({
      productType,
      setProductType,
      productData,
      setProductData,
      setCatalogData,
      finalAssetUrl,
      matchedShapeProduct,
      setFinalAssetUrl,
      state,
      readyToRender,
      dispatch,
    }),
    [
      productType,
      setProductType,
      productData,
      setProductData,
      setCatalogData,
      finalAssetUrl,
      matchedShapeProduct,
      setFinalAssetUrl,
      state,
      readyToRender,
      dispatch,
    ]
  );

  return (
    <ProductFilterContext.Provider value={providerValue}>
      {children}
    </ProductFilterContext.Provider>
  );
};

ProductFilterWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
