import { useRoutes } from "react-router-dom";
import Homepage from "pages/homepage";
import ARExperience from "../pages/arExperience";
import PageNotFound from "pages/pageNotFound";
import { ProductFilterWrapper } from "context/productFilterContext";

const RoutesConfigWrapper = () => {
  const routes = useRoutes([
    { path: "/", element: <Homepage />, exact: true },
    {
      path: "/ar",
      element: (
        <ProductFilterWrapper>
          <ARExperience />
        </ProductFilterWrapper>
      ),
      exact: true,
    },
    {
      path: "*",
      element: <PageNotFound />,
      exact: true,
    },
  ]);
  return routes;
};

export default RoutesConfigWrapper;
