import React, { useEffect, useState } from "react";
import { Stack, Typography, Icon, Button } from "@mui/material";
import warningIcon from "assets/icons/warning-icon.svg";
import PropTypes from "prop-types";

const ErrorStateView = ({
  showRetryCta,
  errorMessage,
  callbackHandler,
  ...props
}) => {
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    setOpacity(1);
  }, []);

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      {...props}
      sx={{
        opacity: opacity,
        transition: "opacity 0.3s",
        backgroundColor: "gray.lightTransparent55",
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        maxWidth={{ xs: "360px", md: "460px" }}
        mt={showRetryCta ? 0 : "-14%"}
      >
        <Icon
          sx={{
            width: { xs: "64px", md: "80px" },
            height: { xs: "64px", md: "80px" },
          }}
        >
          <img
            src={warningIcon}
            className="error-icon"
            alt="service error icon"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </Icon>
        <Typography
          variant="h2"
          mt={2}
          mb={showRetryCta ? { xs: 4, md: 6 } : 2}
        >
          We’re sorry,
          <br /> something went wrong.
        </Typography>
        {errorMessage && errorMessage.length > 0 && (
          <Typography as="body1">{errorMessage}</Typography>
        )}
        {showRetryCta && (
          <Button variant="light" disableRipple onClick={callbackHandler}>
            TRY AGAIN
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

ErrorStateView.propTypes = {
  showRetryCta: PropTypes.bool,
  callbackHandler: PropTypes.func,
  errorMessage: PropTypes.string,
};

ErrorStateView.defaultProps = {
  showRetryCta: false,
  callbackHandler: null,
  errorMessage: "",
};

export default ErrorStateView;
