import RoutesConfigWrapper from "routes";
import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalTheme from "./theme/globalTheme";
import { DevicePropertyWrapper } from "context/devicePropertyContext";
import "./fonts.css";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    // fixing font smoothing issue
    document.body.setAttribute(
      "style",
      "-moz-osx-font-smoothing : grayscale; -webkit-font-smoothing: antialiased"
    );
  });

  return (
    <>
      <ThemeProvider theme={GlobalTheme}>
        <CssBaseline />
        <DevicePropertyWrapper>
          <RoutesConfigWrapper />
        </DevicePropertyWrapper>
      </ThemeProvider>
    </>
  );
}

export default App;
