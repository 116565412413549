import { color } from "./color";
import { breakpoints } from "./breakpoints";
export const button = {
  styleOverrides: {
    root: {
      textTransform: "none",
      bkitTapHighlightColor: "transparent",
      "&:disabled": {
        color: `${color.secondary.transparent55}`,
        backgroundColor: `${color.gray.main}`,
        border: `1px solid ${color.secondary.transparent55}`,
      },
    },
  },
  variants: [
    {
      props: { variant: "dark" },
      style: {
        textTransform: "none",
        color: "white",
        borderRadius: "0px",
        padding: "12px 28px",
        backgroundColor: `${color.secondary.main}`,
        border: `1px solid ${color.secondary.main}`,
        "&:hover": {
          color: "white",
          backgroundColor: `${color.secondary.transparent70}`,
        },
        "&:active": {
          color: "white",
          backgroundColor: `${color.secondary.transparent70}`,
        },
        [`@media screen and (min-width: ${breakpoints.values.sm}px)`]: {
          padding: "12px 40px",
        },
      },
    },
    {
      props: { variant: "light" },
      style: {
        textTransform: "none",
        borderRadius: "0px",
        padding: "10px 28px",
        backgroundColor: `${color.primary.main}`,
        border: `1px solid #000000`,
        "&:hover": {
          backgroundColor: `${color.primary.transparent70}`,
        },
        "&:active": {
          backgroundColor: `${color.primary.transparent70}`,
        },
        [`@media screen and (min-width: ${breakpoints.values.sm}px)`]: {
          padding: "19px 40px",
        },
      },
    },
    {
      props: { variant: "arrowBtn" },
      style: {
        textTransform: "none",
        borderRadius: "0px",
        padding: "0",
        position: "absolute",
        display: "flex",
        zIndex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: `${color.primary.main}`,
        width: "36px",
        height: "100%",
        minWidth: "0px",
        "&:hover": {
          backgroundColor: `${color.primary.transparent70}`,
        },
        "&:active": {
          backgroundColor: `${color.primary.transparent70}`,
        },
        "&:disabled": {
          backgroundColor: `${color.gray.light}`,
          border: "none",
        },
        top: 0,
        [`@media screen and (min-width: ${breakpoints.values.md}px)`]: {
          width: "56px",
        },
      },
    },
  ],
};
