import React, { useEffect } from "react";
import { Container, Stack, Link, Box, Typography } from "@mui/material";
import logo from "assets/icons/logo.svg";
import {
  errorStateEventLogDebounced,
  PellaAnalyticsTag,
} from "utils/analyticsManager";
import { browserName } from "react-device-detect";

const PageNotFound = (props) => {
  useEffect(() => {
    errorStateEventLogDebounced(PellaAnalyticsTag.PAGE_NOT_FOUND_ERROR, {
      page_not_found_url: window.location.href,
      browser_name: browserName,
    });
  });

  return (
    <Box {...props}>
      <header
        id="pella-ar-experience-header"
        data-testid="pella-ar-experience-header"
        style={{
          backgroundColor: "black",
        }}
      >
        <Container
          height="100%"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            padding: "0 !important",
            paddingLeft: "0 !important",
            paddingRight: "0 !important",
          }}
        >
          <Stack
            direction="row"
            spacing={3}
            px={{ xs: 2, xl: 0 }}
            pt={1}
            minHeight="63px"
          >
            <Link data-testid="app-logo-link" href="/">
              <img
                src={logo}
                className="App-logo"
                alt="logo"
                style={{
                  width: "50px",
                  height: "50px",
                  objectFit: "contain",
                }}
              />
            </Link>
          </Stack>
        </Container>
      </header>
      <Container
        sx={{
          display: "flex",
          width: "100vw",
          height: "92vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h2">404 | Page not found</Typography>
      </Container>
    </Box>
  );
};

export default PageNotFound;
