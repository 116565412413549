import { createTheme } from "@mui/material/styles";
import { breakpoints } from "./breakpoints";
import { color } from "./color";
import { typography } from "./typography";
import { button } from "./button";
import { tabs, tab } from "./tabs";

// A custom theme for this app
const GlobalTheme = createTheme({
  breakpoints,
  palette: color,
  typography,
  components: {
    MuiButton: button,
    MuiTabs: tabs,
    MuiTab: tab,
  },
});

export default GlobalTheme;
