import React, { useEffect, useContext, useRef, useState } from "react";
import PropTypes from "prop-types";
import ModelViewerContainer from "components/atoms/modelViewerContainer";
import FiltersContainer from "components/molecules/filtersContainer";
import { Stack } from "@mui/material";
import { DevicePropertyContext } from "context/devicePropertyContext";
import { ProductFilterContext } from "context/productFilterContext";
import { isMobile } from "react-device-detect";

const ProductPanelContainer = ({ data, ...props }) => {
  const filterRef = useRef();
  const [remainHeight, setRemainHeight] = useState(0);
  const [stateFilterOptions, setStateFilterOptions] = useState([]);
  const { isLandscape } = useContext(DevicePropertyContext);
  const { state, productType } = useContext(ProductFilterContext);
  const [containerOpacity, setContainerOpacity] = useState(0);
  const arNavigation = document.getElementById("pella-ar-experience-header");

  useEffect(() => {
    const handleResize = () => {
      let navHeight = arNavigation?.offsetHeight ?? 65;
      let fiterH =
        filterRef.current && filterRef.current.clientHeight === 0
          ? 200
          : filterRef.current.clientHeight;
      let h =
        isLandscape && isMobile
          ? window.innerHeight - navHeight
          : window.innerHeight - fiterH - navHeight;
      setRemainHeight(h);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);
    handleResize();
    setContainerOpacity(1);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, [isLandscape, arNavigation]);

  useEffect(() => {
    if (state.products[productType]?.options) {
      setStateFilterOptions(state.products[productType]?.options);
    } else {
      setStateFilterOptions([]);
    }
  }, [state, productType]);
  return (
    <Stack
      direction={isLandscape ? { xs: "column", sm: "row" } : "column"}
      width="100%"
      alignItems="center"
      overflow="hidden"
      data-testid="product-tab-panel-content"
      px={{ xs: 0, md: isLandscape ? 2 : 0 }}
    >
      <ModelViewerContainer
        id={data.id}
        data-testid="model-viewer-container"
        width={isLandscape ? { xs: "100%", sm: "38%" } : "100%"}
        height={
          isLandscape
            ? { xs: `${remainHeight}px`, md: "92vh" }
            : `${remainHeight}px`
        }
        minHeight="300px"
      />
      <FiltersContainer
        ref={filterRef}
        data-testid="filter-option-container"
        stateFilterOptions={stateFilterOptions}
        width={isLandscape ? { xs: "100%", sm: "62%" } : "100%"}
        flex="0 1 auto"
        backgroundColor={
          isLandscape ? { xs: "white", sm: "transparent" } : "white"
        }
        sx={{
          opacity: containerOpacity,
          transition: "opacity 0.3s",
          minHeight: "150px",
        }}
        data={data}
      />
    </Stack>
  );
};

ProductPanelContainer.propTypes = {
  data: PropTypes.object,
};

ProductPanelContainer.defaultProps = {
  data: null,
};

export default ProductPanelContainer;
