import React, { createContext, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { checkIsARAvailable } from "utils/arSupportCheck";
import { isIOS } from "react-device-detect";

export const DevicePropertyContext = createContext();

export const DevicePropertyWrapper = ({ children }) => {
  const [isARAvailable, setIsARAvailable] = useState(false);
  const [orientation, setOrientation] = useState("landscape");
  const [isLandscape, setIsLandscape] = useState(true);

  const updateOrientation = (event) => {
    if (isIOS) {
      let type = window.matchMedia("(orientation:landscape)").matches;
      setIsLandscape(type);
      setOrientation(type ? "landscape" : "portrait");
    } else {
      let screentype =
        (window.screen.orientation || {}).type ||
        window.screen.mozOrientation ||
        window.screen.msOrientation;
      if (screentype) {
        setOrientation(screentype);
        setIsLandscape(screentype.includes("landscape"));
      }
    }
  };

  useEffect(() => {
    setIsARAvailable(checkIsARAvailable());
    updateOrientation();
    window.addEventListener("resize", updateOrientation);
    window.addEventListener("orientationchange", updateOrientation);
    return () => {
      window.removeEventListener("resize", updateOrientation);
      window.removeEventListener("orientationchange", updateOrientation);
    };
  }, []);

  const providerValue = useMemo(
    () => ({ orientation, isLandscape, isARAvailable }),
    [orientation, isLandscape, isARAvailable]
  );

  return (
    <DevicePropertyContext.Provider value={providerValue}>
      {children}
    </DevicePropertyContext.Provider>
  );
};

DevicePropertyWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
