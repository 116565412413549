import { breakpoints } from "./breakpoints";

export const typography = {
  fontFamily: ['"Gantari-Regular"', "Arial", "sans-serif"].join(","),
  htmlFontSize: 16,
  h2: {
    fontFamily: "Gantari-Bold",
    fontSize: "1.75rem",
    lineHeight: "110%",
    [`@media screen and (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: "2.625rem",
    },
  },
  body1: {
    fontSize: "1rem",
    lineHeight: "140%",
    [`@media screen and (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: "1.5rem",
    },
  },
  body2: {
    fontSize: "0.75rem",
    lineHeight: "132%",
    [`@media screen and (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: "0.875rem",
    },
  },
  italicBody2: {
    fontFamily: "Gantari-Italic",
    fontSize: "0.75rem",
    lineHeight: "132%",
    [`@media screen and (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: "0.875rem",
    },
  },
  button: {
    fontFamily: "Gantari-SemiBold",
    fontSize: "1rem",
    lineHeight: "140%",
    [`@media screen and (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: "1.125rem",
    },
  },
};
