export const color = {
  background: {
    default: "#F8F9FA",
  },
  landingPage: {
    bodyBackground: "#DCD6CF",
    transparent75: "rgba(0, 0, 0, 0.75)",
    light: "#FFFFFF",
    dark: "#000000",
  },
  primary: {
    main: "#FFDE00",
    light: "#ffde001f",
    transparent70: "#ffde00b3",
  },
  gray: {
    main: "#E6E7E8",
    light: "#F8F9FA",
    lightTransparent55: "#F8F9FAf2",
  },
  secondary: {
    main: "#000000",
    transparent55: "#0000008c",
    transparent70: "#000000b3",
  },
  tabslight: {
    main: "#FFFFFFFF",
    light: "#FFFFFFB3",
    transparent95: "#fffffff2",
  },
  tabsdark: {
    main: "#000000FF",
    light: "#000000B3",
  },
};
