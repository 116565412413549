import React, { useEffect, useContext, useState } from "react";
import { Container, Stack, Link, Box } from "@mui/material";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { dataCall, API_URL_PATH, METHODS } from "utils/api";
import logo from "assets/icons/logo.svg";
import {
  ProductFilterContext,
  ProductFilterActionTypes,
} from "context/productFilterContext";
import { TabPanel, TabContext } from "@mui/lab";
import ProductPanelContainer from "components/organisms/productPanelContent";
import TabsController from "components/molecules/tabsController";
import PropTypes from "prop-types";
import ErrorStateView from "components/atoms/errorStateView";
import Loader from "components/atoms/loader";
import { DevicePropertyContext } from "context/devicePropertyContext";
import {
  pageViewEventLogDebounced,
  errorStateEventLogDebounced,
  PellaAnalyticsTag,
} from "utils/analyticsManager";
import { browserName } from "react-device-detect";
import { delay } from "lodash";

const ARExperience = ({ testData, catalogTestData, ...props }) => {
  const [showLoader, setShowLoader] = useState(true);
  const [showServiceError, setShowServiceError] = useState(false);
  const [loaderOpacity, setLoaderOpacity] = useState(1);
  const {
    productType,
    setProductType,
    productData,
    setProductData,
    setCatalogData,
    dispatch,
    readyToRender,
  } = useContext(ProductFilterContext);
  const { isARAvailable } = useContext(DevicePropertyContext);

  const delayDisappearLoader = () => {
    setLoaderOpacity(0);
    delay(() => {
      setShowLoader(false);
    }, 1000);
  };

  const triggerErrorEventLog = (url) => {
    errorStateEventLogDebounced(PellaAnalyticsTag.API_REQUEST_ERROR, {
      api_url: url,
      browser_name: browserName,
    });
  };

  const serviceRequest = () => {
    setLoaderOpacity(1);
    setShowLoader(true);
    dataCall(API_URL_PATH.PRODUCT, METHODS.GET, null, false)
      .then((data) => {
        setProductData(data);
        dataCall(API_URL_PATH.CATALOG, METHODS.GET, null, false)
          .then((catalog) => {
            let products = catalog.variants;
            setCatalogData(products);
            setShowServiceError(false);
            delayDisappearLoader();
          })
          .catch((error) => {
            triggerErrorEventLog(API_URL_PATH.CATALOG);
            setShowServiceError(true);
            delayDisappearLoader();
          });
      })
      .catch((error) => {
        triggerErrorEventLog(API_URL_PATH.PRODUCT);
        setShowServiceError(true);
        delayDisappearLoader();
      });
  };

  useEffect(() => {
    if (testData && catalogTestData) {
      setProductData(testData);
      let products = catalogTestData.variants;
      setCatalogData(products);
    } else {
      serviceRequest();
    }
  }, [testData, catalogTestData, setProductData, setCatalogData]); // eslint-disable-line react-hooks/exhaustive-deps

  const trackProductTypeChange = (
    productData,
    productType,
    isARAvailable,
    browserName
  ) => {
    if (
      productData &&
      productData.products &&
      productData.products.length > 0
    ) {
      const productName = productData.products[productType]?.product_type ?? "";
      if (productName) {
        pageViewEventLogDebounced(
          PellaAnalyticsTag.AR_CONFIGURATION + `${productName.toLowerCase()}`,
          isARAvailable,
          browserName
        );
      }
    }
  };

  useEffect(() => {
    trackProductTypeChange(
      productData,
      productType,
      isARAvailable,
      browserName
    );
  }, [productData, productType, isARAvailable, browserName]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (newValue) => {
    setProductType(newValue);
    dispatch({
      type: ProductFilterActionTypes.CHANGE_PRODUCT_TYPE,
    });
  };

  const displayErrorState = () => {
    if (showServiceError) {
      return (
        <ErrorStateView
          callbackHandler={serviceRequest}
          showRetryCta={true}
          width="100vw"
          height="90vh"
        />
      );
    }
    return null;
  };

  return (
    <HelmetProvider>
      <Box {...props}>
        <Helmet>
          <script
            type="module"
            src="https://ajax.googleapis.com/ajax/libs/model-viewer/3.0.1/model-viewer.min.js"
          ></script>
        </Helmet>

        <header
          id="pella-ar-experience-header"
          data-testid="pella-ar-experience-header"
          style={{
            backgroundColor: "black",
          }}
        >
          <Container
            height="100%"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              padding: "0 !important",
              paddingLeft: "0 !important",
              paddingRight: "0 !important",
            }}
          >
            <Stack
              direction="row"
              spacing={3}
              px={{ xs: 2, xl: 0 }}
              pt={1}
              minHeight="63px"
            >
              <Link data-testid="app-logo-link" href="/">
                <img
                  src={logo}
                  className="App-logo"
                  alt="logo"
                  style={{
                    width: "50px",
                    height: "50px",
                    objectFit: "contain",
                  }}
                />
              </Link>
              {productData && productData?.products?.length > 0 && (
                <TabsController
                  id="product"
                  data-testid="product-tabs-control"
                  options={productData.products}
                  selectedType={productType}
                  selectionCallback={handleChange}
                  typeKey="product_type"
                  nameKey="product_name"
                />
              )}
            </Stack>
          </Container>
        </header>
        {showLoader && <Loader opacity={loaderOpacity} />}
        {displayErrorState()}
        {readyToRender && (
          <Container
            as="section"
            data-testid="product-tab-panels-container"
            sx={{
              paddingLeft: "0 !important",
              paddingRight: "0 !important",
            }}
          >
            <TabContext value={`${productType}`}>
              {productData &&
                productData?.products?.length > 0 &&
                productData.products.map((category, index) => {
                  return (
                    <TabPanel
                      role="tabpanel"
                      value={`${index}`}
                      key={category.filter_id + "_" + category.id}
                      index={category.filter_id + "_" + category.id}
                      sx={{
                        padding: "0 !important",
                        paddingLeft: "0 !important",
                        paddingRight: "0 !important",
                      }}
                    >
                      <ProductPanelContainer data={category} />
                    </TabPanel>
                  );
                })}
            </TabContext>
          </Container>
        )}
      </Box>
    </HelmetProvider>
  );
};

ARExperience.propTypes = {
  testData: PropTypes.object,
  catalogTestData: PropTypes.object,
};

ARExperience.defaultProps = {
  testData: null,
  catalogTestData: null,
};

export default ARExperience;
