import PubSub from "pubsub-js";
/* eslint-disable-line no-console */
// fetch call
function getCookie(name) {
  if (!document.cookie) {
    return null;
  }
  const xsrfCookies = document.cookie
    .split(";")
    .map((c) => c.trim())
    .filter((c) => c.startsWith(`${name}=`));
  if (xsrfCookies.length === 0) {
    return null;
  }
  return decodeURIComponent(xsrfCookies[0].split("=")[1]);
}
// get ENV based REACT_APP_BASE_URL, if not available fallback using qa url
const baseURL = process.env.REACT_APP_BASE_URL;

// get ENV based REACT_APP_AUTH_TOKEN, if not available fallback using default qa token
const authToken = process.env.REACT_APP_AUTH_TOKEN;

export const API_URL_PATH = {
  PRODUCT: `${baseURL}/api/v1/getAllProductFilters/`,
  CATALOG: `${baseURL}/api/v1/getAllProducts/`,
  PRODUCT_MOCK: "./data/products.json",
  CATALOG_MOCK: "./data/catalog_v2.json",
};

export const METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DEL: "DELETE",
};

const REDIRECT_URL = {
  LOGIN: "user/login/",
  HOME: "home",
};

const HTTP_STATUS = {
  200: {
    status: 200,
    name: "SUCCESS",
    failure: false,
  },
  401: {
    name: "AUTH",
    status: 401,
    message: "Session timed out. Please login again.",
    action: REDIRECT_URL.LOGIN,
    failure: true,
  },
  403: {
    name: "PERMISSION",
    status: 403,
    message: "You are not authorized to view this page.",
    action: REDIRECT_URL.HOME,
    failure: true,
  },
  500: {
    name: "FAILURE",
    status: 500,
    message: "Something went wrong, please try again later",
    action: REDIRECT_URL.HOME,
    failure: true,
  },
  400: {
    name: "ERROR",
    status: 400,
    message: "Something went wrong, please try again later - 400",
    failure: true,
  },
};

class PellaAPIError extends Error {
  constructor(errorObj) {
    super("Error");
    this.statusText = errorObj.statusText;
    this.statusCode = errorObj.statusCode || 500;
    this.name = "PellaAPIError";
  }
}

const getFetchOptions = (method, body = null, upload = false) => {
  const options = {
    method: METHODS[method],
    headers: {
      "x-csrftoken": getCookie("csrftoken"),
      "Content-type": "application/json",
      Authorization: `token ${authToken}`,
    },
    credentials: "include",
    redirect: "follow",
    withCredentials: true,
    ...(body && { body: upload ? body : JSON.stringify(body) }),
  };
  if (upload) {
    delete options.headers["Content-type"];
  }
  return options;
};

async function handleErrors(response) {
  const result = await response.json();
  if (!response.ok) {
    PubSub.publish("API_ERROR_RESULT", result);
    throw new PellaAPIError({
      statusCode: response.status,
      statusText: result.error,
    });
  }
  return result;
}

export function dataCall(url, method, body, catchInternally = true) {
  if (catchInternally) {
    return fetch(url, getFetchOptions(method, body))
      .then(handleErrors)
      .then((response) => response)
      .catch((error) => ({
        ...HTTP_STATUS[error.statusCode],
        ...(error.statusText && { message: error.statusText }),
      }));
  } else {
    return fetch(url, getFetchOptions(method, body))
      .then(handleErrors)
      .then((response) => response);
  }
}

export function uploadCall(url, data) {
  return new Promise((res, rej) => {
    fetch(url, getFetchOptions("POST", data, true))
      .then((res) => {
        return res.json();
      })
      .then((data) => res(data))
      .catch((ex) => {
        console.log(ex);
        rej(ex);
      });
  });
}

export function downloadCall(url, method, body, data) {
  return new Promise((res) => {
    return fetch(url, getFetchOptions(method, body))
      .then((res) => {
        // eslint-disable-line
        if (!res.ok) {
          return false;
        } else {
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          const csvfile = res.blob();
          csvfile.then((blob) => {
            const objectURL = URL.createObjectURL(blob);
            a.href = objectURL;
            a.download = data;
            a.click();
            URL.revokeObjectURL(objectURL);
          });
          return true;
        }
      })
      .then((data) => res(data));
  }).catch((err) => console.log(err));
}

export function staticDownloadCall(csv, fileName) {
  const csvFile = new Blob([csv], { type: "text/csv" });
  const downloadLink = document.createElement("a");
  downloadLink.download = `${fileName}.csv`;

  downloadLink.href = window.URL.createObjectURL(csvFile);
  downloadLink.style.display = "none";
  document.body.appendChild(downloadLink);

  downloadLink.click();
}
