import {
  filter,
  differenceWith,
  isEqual,
  groupBy,
  forIn,
  each,
  uniqBy,
} from "lodash";

// function to check if current selected filter id is available compare to catalog
export const checkFilterSelectedIdAvailable = (
  matchedProduct,
  filterId,
  selectedId,
  selectedName
) => {
  let availableOptions = matchedProduct ? matchedProduct[filterId] : [];
  let matched = filter(availableOptions, (option) => {
    return option.id === selectedId;
  })[0];
  if (matched) {
    // if matched meaning user's selected option is available, then return as it is
    return { selectedId: selectedId, selectedName: selectedName };
  } else {
    // if can't find matched, then default using the first availableOptions form the catalog item filter options
    return {
      selectedId: availableOptions[0].id,
      selectedName: availableOptions[0].name,
    };
  }
};

// find matched catalog shape product based on windows or doors selected shape
export const getMatchedCatalogShape = (
  filteredCatalog,
  productTypeName,
  shapeName
) => {
  let matched = filter(filteredCatalog, { type: productTypeName })[0];
  let matchedProduct = null;
  if (matched) {
    matchedProduct = filter(matched.products, {
      type: shapeName,
    })[0];
  }

  return matchedProduct;
};

// find matched catalog item based on user selected filter options
export const findMatchedFilteredItem = (
  filteredCatalog,
  filterOptions,
  productTypeName
) => {
  let assetName = "",
    matchedProduct = null;
  // find the matched window or door catalog
  let matched = filter(filteredCatalog, (catalog) => {
    let type = catalog.type.toLowerCase();
    let pType = productTypeName.toLowerCase();
    return type === pType;
  })[0];

  if (matched) {
    let selectedAttributes = [];
    let shapeName = "";
    // create array of attributes from user filter selection, to compare with catalog item attributes later
    selectedAttributes = filterOptions.map((option) => {
      if (option.filterId === "sh") {
        // find the shape name to serach the right shape product user is looking for
        shapeName = option.selectedName;
      }
      return {
        filter_id: option.filterId,
        id: option.selectedId,
        attribute_name: option.selectedName,
      };
    });
    // find the right shape product
    matchedProduct = filter(matched.products, {
      type: shapeName,
    })[0];
    // if found, compare catalog items' attributes with selectedAttributes att
    if (matchedProduct) {
      let result = filter(matchedProduct.catalog, (item) => {
        let dif = differenceWith(item.attributes, selectedAttributes, isEqual);
        return dif.length === 0 ? item : false;
      })[0];
      assetName = result?.asset_3d ? result?.asset_3d : "";
    }
  }
  return assetName;
};

// function to parse catalog data into window or door type along with associated shape products
export const getFilteredCatelogGroup = (catalogData, productData) => {
  let group = [];
  // group by window or door product type
  group = groupBy(catalogData, (product) => {
    return product.product_type;
  });
  let catalogList = [];
  forIn(group, (value, key) => {
    // group by shape type
    let shapeGroup = groupBy(value, (product) => {
      let matched = filter(product.attributes, { filter_id: "sh" });
      let found = matched[0];
      return found.attribute_name;
    });
    let obj = {
      type: key,
      products: getSanitizedShapeList(productData, key, shapeGroup),
    };
    catalogList.push(obj);
  });
  return catalogList;
};

// private function to sanitized shape product along with easy access filter options
const getSanitizedShapeList = (productData, type, shapeGroup) => {
  let product = productData.products.filter((product) => {
    let productType = product.product_type.toLowerCase();
    let compareType = type.toLowerCase();
    return productType === compareType;
  })[0];
  // find windows or doors filter categories
  let categories = product?.categories;
  let list = [];
  // iterate thru shapeGroup, and create object for each shape & hold it's colors, grilles, or sizes options
  // along with catalog list
  forIn(shapeGroup, (value, key) => {
    let obj = {
      type: key,
      catalog: value,
    };
    // the logic below is to find types of colors, grilles, or sizes for each shape
    // in order to know what filter options to hide on UI
    each(categories, (c) => {
      let flist = [];
      let filterId = c.filter_id;
      if (filterId !== "sh") {
        each(value, (item) => {
          each(item.attributes, (attribute) => {
            if (attribute.filter_id === filterId) {
              flist.push({ id: attribute.id, name: attribute.attribute_name });
            }
          });
        });
        // only get the unique items, remove duplicate ones
        flist = uniqBy(flist, "id").sort((a, b) => {
          return a.id - b.id;
        });
        obj[filterId] = flist;
      } else {
        // get shape id and add to obj for easier search
        let item = value[0];
        let found = filter(item.attributes, { filter_id: "sh" })[0];
        obj.id = found.id;
      }
    });
    list.push(obj);
  });
  return list;
};
